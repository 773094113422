import {createAsyncThunk, createEntityAdapter, createSelector, createSlice} from '@reduxjs/toolkit'
import {getMachineSelected} from './schedeMachineDetailSlice';
import {addOneMachinery, selectAllMachinery, updateOneMachinery} from './listMachinerySlice';
import {API} from 'aws-amplify';
import {GRAPHQL_AUTH_MODE} from '@aws-amplify/api'

import {listPlants} from '../../../graphql/queries';
import {createPlants, deletePlants, updatePlants} from "../../../graphql/mutations";
import * as subscriptions from "../../../graphql/subscriptions";

/**
 * nella promise per il download aggiungere il campo selected al primo plant scaricato e modificare
 * i successivi con la variabile selected false cosi da gestire al meglio la struttura dati di back end
 */


const plantAdapter = createEntityAdapter({
    selectId: (plant) => plant.id,
    sortComparer: (a, b) => a.id.localeCompare(b.id)
})

export const subscribeCreatePlant = createAsyncThunk(
    'listPlant/subscriptionCreatePlant',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onCreatePlants,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({provider, value}) => {
                    dispatch(plantAdded({
                        id: value.data.onCreatePlants.id,
                        nome: value.data.onCreatePlants.name,
                        posGeo: value.data.onCreatePlants.posGeo,
                        via:value.data.onCreatePlants.via,
                        createdAt: value.data.onCreatePlants.createdAt,
                        updatedAt: value.data.onCreatePlants.updatedAt,
                    }))
                },
                error: (error) => console.warn(error)
            })

        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);
export const subscribeModifyPlant = createAsyncThunk(
    'listPlant/subscriptionModifyPlant',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onUpdatePlants,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {


                    dispatch(
                        updateOnePlant({
                            id:value.data.onUpdatePlants.id,
                            nome: value.data.onUpdatePlants.name,
                            posGeo: value.data.onUpdatePlants.posGeo,
                            via:value.data.onUpdatePlants.via,
                            createdAt: value.data.onUpdatePlants.createdAt,
                            updatedAt: value.data.onUpdatePlants.updatedAt,
                        })
                    )
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);
export const subscribeDeletePlant = createAsyncThunk(
    'listPlant/subscriptionDeletePlant',
    async (_, { dispatch }) => {
        const subscription =
            API.graphql({
                query: subscriptions.onDeletePlants,
                authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
            }).subscribe({
                next: ({ provider, value }) => {

                    dispatch(deleteOnePlant(value.data.onDeletePlants.id))
                },
                error: (error) => console.warn(error)
            })
        // questo oggetto serve per la gestione della subscription e non per la modifica del dato in forntend
        // return subscription;
    }
);
export const addPlant = createAsyncThunk("plant/createPlant", async ({name, posGeo, via}) => {

    const response = await API.graphql({
        query: createPlants,
        variables: {
            input: {
                name,
                posGeo,
                via
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return {
        id: response.data.createPlants.id,
        nome: response.data.createPlants.name,
        posGeo: response.data.createPlants.posGeo,
        via:response.data.createPlants.via,
        createdAt: response.data.createPlants.createdAt,
        updatedAt: response.data.createPlants.updatedAt,
    }

})

export const updatePlant = createAsyncThunk("plant/updatePlant", async ({id,name, posGeo, via}) => {

    const response = await API.graphql({
        query: updatePlants,
        variables: {
            input: {
                id,
                name,
                posGeo,
                via
            }
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return {
        id: response.data.updatePlants.id,
        nome: response.data.updatePlants.name,
        posGeo: response.data.updatePlants.posGeo,
        via:response.data.updatePlants.via,
        createdAt: response.data.updatePlants.createdAt,
        updatedAt: response.data.updatePlants.updatedAt,
    };
})

export const deletePlant = createAsyncThunk("plant/deletePlant", async ({id}) => {

    const response = await API.graphql({
        query: deletePlants,
        variables: {
            input: {id: id}
        },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })
    return response.data.deletePlants.id
})
export const getlistPlants = createAsyncThunk("plant/listPlant", async (a, {getState}) => {
    /*
    const dataAttuale = new Date().toISOString()
    const dateLastElementString = Object.values(getState().plants.entities).sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))[0].updatedAt

    const dateLastElement = new Date(Date.parse(dateLastElementString) + 1000).toISOString()


    //console.log("data ultimo elemento aggiornato impianti",dateLastElementString)
    */
    //DA RISOLVERE IL FILTRAGGIO PER EVITARE CHE SI SCARICHI OGNI VOLTA TUTTA LA LISTA IMPIANTI
    const response = await API.graphql({
        query: listPlants,

        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    })

    return response.data.listPlants.items.map((el, index) => {
        return {
            id: el.id,
            nome: el.name,
            posGeo: el.posGeo,
            via: el.via,
            createdAt: el.createdAt,
            linkDataGraph: el.linkDataGraph,
            updatedAt: el.updatedAt,
            selected: index === 0 ? true : false
        }

    })
})


const plantSlice = createSlice({
    name: "plant",
    initialState: plantAdapter.getInitialState(),
    reducers: {
        plantAdded: plantAdapter.upsertOne,
        plantMoreAdded: plantAdapter.upsertMany,
        updateOnePlant: plantAdapter.upsertOne,
        deleteOnePlant: plantAdapter.removeOne,
        selectedPlant(state, action) {
            const {plantId} = action.payload
            Object.values(state.entities).map(el => el.selected = false)
            Object.values(state.entities)[Object.values(state.entities).findIndex(el => el.id === plantId)].selected = true
        }

    },
    extraReducers: bulder =>
        bulder
            .addCase(addPlant.fulfilled, plantAdapter.upsertOne)
            .addCase(addPlant.rejected, (state, action) => console.log(state, action))
            .addCase(updatePlant.fulfilled, plantAdapter.upsertOne)
            .addCase(updatePlant.rejected, (state, action) => console.log(state, action))
            .addCase(deletePlant.fulfilled, plantAdapter.removeOne)
            .addCase(deletePlant.rejected, (state, action) => console.log(state, action))
            .addCase(getlistPlants.fulfilled, plantAdapter.upsertMany)
            .addCase(getlistPlants.rejected, (state, action) => console.log("rejected getlistplant", state, action))
})


export const {
    plantMoreAdded,
    plantAdded,
    selectedPlant,
    deleteOnePlant,
    updateOnePlant
} = plantSlice.actions

export default plantSlice.reducer


//definizione dei selettori di default di adapter per restiturire e connettesi allo stato
export const {selectAll: selectPlant} = plantAdapter.getSelectors(state => state.plants)


// in questa sezione possiamo aggiungere ulteriori selettori che approfondiscono la select

export const selectPlants = createSelector(selectPlant, state => {
    return state
})

export const getSelectPlant = createSelector(selectPlant, state => {
    let temp = state[state.findIndex(el => el.selected === true)]
    return temp
})


export const getNamePlantForSelectedMachinery = createSelector(
    [selectPlant, getMachineSelected, selectAllMachinery],
    (state, select, listMachine) => {
        if (select) {

            const idPlant = listMachine.filter(el => el.id === select.id)[0]?.idPlant

            return state.filter(el => el.id === idPlant)[0]?.nome
        }
    }
)