
/*
export const reparti = [
    {

        HiMedio: 85,
        impianto: "A1",
        listaMacchinari: [
            {
                reparto: "uno",
                nome: "alpha",
                id: "85eu93",
                famiglia: "Macchine rotanti",
                hi: 85,
            }
        ]

    },
    {

        impianto: "A2",
        HiMedio: 87,
        listaMacchinari: [
            {
                nome: "Beta",
                id: "246qet",
                famiglia: "Macchine rotanti",
                hi: 87,
            }
        ]
    },
    {

        impianto: "A3",
        HiMedio: 86,
        listaMacchinari: [
            {
                nome: "Beta",
                id: "123abc",
                famiglia: "Macchine rotanti",
                hi: 86,
            }
        ]
    }
]
*/

import { dataOdierna, giorno } from "./datiInterventi"



export const familyData = [
    {
        id: "trn0",
        famiglia: "Tornio"

    },
    /*
    {
        id: "acm0",
        famiglia: "AC-Motor"

    },
    {
        id: "atr0",
        famiglia: "Autotrasformatore MT-BT"

    },
    {
        id: "acf0",
        famiglia: "Accumulatore fotovoltaico"
    },
    */
    {
        id: "mdt0",
        famiglia: "Macchina da taglio"
    },
    /*
    {
        id: "qeg0",
        famiglia: "Quadro elettrico"
    },
    */
    {
        id: "lmn0",
        famiglia: "Laminatrice"
    },
    /*
    {
        id: "srb0",
        famiglia: "Serbatoio"
    },
    {
        id: "inv0",
        famiglia: "Inverter"
    },
    {
        id: "cmp0",
        famiglia: "Compressore"

    },
    {
        id:"pft0",
        famiglia:"Pannelli fotovoltaici"
    }, 
    {
        id:"dst0",
        famiglia:"Distribuzione aria"
    },
    {
        id:"est0",
        famiglia:"Estrusore"
    },
    {
        id:"rmh0p",
        famiglia:"Robot mobile Helko"
    }
    */
]
export const faultFamily = [
    /**
     * per l'id del fault utilizziamo una prima parte che indica il codice macchinario ed una seconda parte che identifica il cofice fault in questo modo posso creare la tabella con una primary e sortkey per ottimizzare la ricerca
     */
    {
        nome: "Guasto fase-fase",
        MTR: 10,
        id: "acm0-gff",
        famigliaId: "acm0",
        desc: "problema di isolamento",
        component: "Statore",

    },
    {
        nome: "Guasto fase-terra",
        MTR: 10,
        id: "acm0-gf0",
        famigliaId: "acm0",
        desc: "problema di isolamento",
        component: "Statore",
    },
    {
        nome: "Eccentricità",
        MTR: 10,
        id: "acm0-e01",
        famigliaId: "acm0",
        desc: "deformazione del rotore",
        component: "Rotore",
    },
    {
        nome: "Guasto barra",
        MTR: 10,
        id: "acm0-b01",
        famigliaId: "acm0",
        desc: "funzionamento anomalo della barra",
        component: "Rotore",
    },
    {
        nome: "Guasto canalina esterna",
        MTR: 10,
        id: "acm0-cec",
        famigliaId: "acm0",
        desc: "rottura della canalina esterna che contiene il cuscinetto",
        component: "Cuscinetto",
    },
    {
        nome: "Guasto canalina interna",
        MTR: 10,
        id: "acm0-c0c",
        famigliaId: "acm0",
        desc: "rottura della canalina interna che contiene il cuscinetto",
        component: "Cuscinetto",
    },
    {
        nome: "Guasto elemento rotante",
        MTR: 10,
        id: "acm0-e0c",
        famigliaId: "acm0",
        desc: "rottura del cuscinetto",
        component: "Cuscinetto",
    },
    ,
    {
        nome: "Anomalia batteria",
        MTR: 10,
        id: "rmh0p-ab0",
        famigliaId: "rmh0p",
        desc: "Batteria con scarica anomala",
        component: "Batteria",
    },
    {
        nome: "Gomme usurate",
        MTR: 10,
        id: "rmh0p-gs0",
        famigliaId: "rmh0p",
        desc: "Limite di percorrenza raggiunto",
        component: "Gomme",
    },
]

export const dataRotturaRobotMobile = [
    {
        idFault: "rmh0p-gs0",
        hi: 90,
        RUL: new Date(dataOdierna.getTime() + 200 * giorno).toISOString().slice(0, 10)
    },
    {
        idFault: "rmh0p-ab0",
        hi: 80,
        RUL: new Date(dataOdierna.getTime() + 120 * giorno).toISOString().slice(0, 10)
    },
]

export const listaMacchinari = [
    {
        "id": "793af669-3115-4436-8de3-fc768a1f2b18",
        "nome": "Fittizio",
        "identityCode": "line 2 a1",
        "familyId": "acm0",
        "reparto": null,
        "idPlant": "349add6e-2e23-4cb6-8b48-48b4e82a7409",
        "idLinea": "fb958bf4-a47c-46ba-a39a-2492d9bd9b38",
        "idDepartment": "6293aa0d-eb8b-4cfb-81b4-e3ba8ed8ea31",
        "createdAt": "2024-01-25T19:37:26.932Z",
        "updatedAt": "2024-01-25T19:37:33.782Z"
    }
]
//     
export const datiTarga = [

    {
        //nome:"motore2",
        id: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        //familyId: "acm0",
        hi: 65,
       

    },
    /*
    {
        id: "85eu93",
        imageURL: "",
        annoProd: "1994",
        annoInst: "1996",
        produttore: "Oriental motor",

    },
    {
        id: "4172-b0a9",
        imageURL: "",
        annoProd: "2000",
        annoInst: "2002",
        produttore: "Bonfiglioli",
    },
    {
        id: "58dd-4441",
        imageURL: "",
        annoProd: "2000",
        annoInst: "2002",
        produttore: "Oriental motor",
    },
    {
        id: "4172-b0f9",
        imageURL: "",
        annoProd: "2000",
        annoInst: "2002",
        produttore: "Bonfiglioli",
    },
    {
        id: "58dd-4541",
        imageURL: "",
        annoProd: "2000",
        annoInst: "2002",
        produttore: "Bonfiglioli",
    },
    {
        id: "4172-a0a9",
        imageURL: "",
        annoProd: "2000",
        annoInst: "2002",
        produttore: "Oriental motor",
    }
    */
]

export const docDatiTargaData = [
    /*
    {
        id: "4172",
        idMachinery: "4172-b0a9",
        nome: "schema tecnico",
        desc: "schemi e progetto del macchinario",
        link: "",
        date: "2023-01-20",
        note: ""
    }
    */
]

export const faultDataMachinery = [
    {
        //id dello specifico fault calcolato
        id: "84e0ba8-b9c2-4081-84d4-d04a9ae16af1",
        //id della macchina id di backend
        idMachinery: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        //id fault
        idFault: "acm0-gff",
        hi: 10,
        RUL: new Date(dataOdierna.getTime() + 20 * giorno).toISOString().slice(0, 10),
    },
    {

        id: "84e0baf8-b9c2-408-84d4-d04a9ae16af1",
        idMachinery: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idFault: "acm0-b01",
        hi: 22,
        RUL: new Date(dataOdierna.getTime() + 50 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "84e0ba8-b9c2-4081-84d4-d04a9ae16af1",
        idMachinery: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idFault: "acm0-e01",
        hi: 50,
        RUL: new Date(dataOdierna.getTime() + 250 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "84e0baf8-b9c2-4081-84d4-d04a9ae6af1",
        idMachinery: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idFault: "acm0-gf0",
        hi: 20,
        RUL: new Date(dataOdierna.getTime() + 10 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "4e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idMachinery: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idFault: "acm0-cec",
        hi: 40,
        RUL: new Date(dataOdierna.getTime() + 127 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "84e0baf8-b9c2-4081-84d4-d049ae16af1",
        idMachinery: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idFault: "acm0-c0c",
        hi: 24,
        RUL: new Date(dataOdierna.getTime() + 24 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "84e0bf8-b9c2-4081-84d4-d04a9ae16af1",
        idMachinery: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idFault: "acm0-e0c",
        hi: 50,
        RUL: new Date(dataOdierna.getTime() + 300 * giorno).toISOString().slice(0, 10)
    },

    //"acm0-gff""acm0-gf0""acm0-e01""acm0-b01""acm0-cec""acm0-c0c""acm0-e0c"
    /*

    {

        id: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idMachinery: "85eu93",
        idFault: "acm0-b01",
        hi: 36,
        RUL: new Date(dataOdierna.getTime() + 21 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idMachinery: "85eu93",
        idFault: "acm0-gff",
        hi: 7,
        RUL: new Date(dataOdierna.getTime() + 22 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idMachinery: "85eu93",
        idFault: "acm0-gf0",
        hi: 48,
        RUL: new Date(dataOdierna.getTime() + 89 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "84e0baf8-b9c2-4081-84d4-d04a9ae16af1",
        idMachinery: "85eu93",
        idFault: "acm0-e01",
        hi: 50,
        RUL: new Date(dataOdierna.getTime() + 200 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "e68ac9",
        idMachinery: "85eu93",
        idFault: "acm0-cec",
        hi: 19,
        RUL: new Date(dataOdierna.getTime() + 28 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "e6834c",
        idMachinery: "85eu93",
        idFault: "acm0-c0c",
        hi: 56,
        RUL: new Date(dataOdierna.getTime() + 130 * giorno).toISOString().slice(0, 10)
    },
    {

        id: "e68a43c",
        idMachinery: "85eu93",
        idFault: "acm0-e0c",
        hi: 52,
        RUL: new Date(dataOdierna.getTime() + 216 * giorno).toISOString().slice(0, 10)
    },

*/


]


export const accise = [
    "2024-01-26",
    "2024-01-29",
    "2024-01-30",
    "2024-01-31",
    "2024-02-01",
    "2024-02-02",
    "2024-02-05",   
   "2024-02-06"
]


export const p_battery = [
    62.0,
    78.0,
    83.0,
    80.0,
    83.0,
    73.0,
    80.0,
    80.0,

]


export const distanza_cumulativa =[
    629,
    4244,
    6099,
    7887,
    10354,
    12134,
    12344
]