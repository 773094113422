/**
budget relativo ad interventi ordinari (sostituzione pezzi) e manutenzione straordinaria (capitolo 3 del documento sulle metriche)
tempo totale di manutenzione (paragrafo 4.2.2 del documento sulle metriche)
rapportoODT tra il tempo effettivo degli interventi programmati rispetto al tempo totale speso per gli interventi (paragrafo 4.1.7 del documento sulle metriche)
rapportoODT tra il numero di interventi programmati completati rispetto il totale degli interventi programmati (paragrafo 4.1.8 del documento sulle metriche)

        percentuale degli interventi eseguiti in tempo (metrica da approfondire) on time delivery
        
        rapportoODT tra tempo stimato rispetto all'effettivo degli interventi, proposto per macchina, linea, reparto e stabilimento (paragrafo 4.2.1 del documento sulle metriche)
        
        
        
*/



import { useDispatch, useSelector } from "react-redux"
import { selectAllIntervention } from "../root/slices/interventionSlice"
import { selectAllBudget } from "../root/slices/budgetSlice"
import { selectAllDepartment } from "../root/slices/listDepartmentSlice"
import { selectAllLine } from "../root/slices/listLineSlice"
import { selectAllMachinery } from "../root/slices/listMachinerySlice"
import { selectPlant } from "../root/slices/plantSlice"
import { selectAllReport } from "../root/slices/reportSlice"

import './mainMetriche.css'
import { useEffect, useState } from "react"
import { costoPerAsset } from "../../functions/metriche/budget"
import { giorniInizioAnno } from "../../functions/dateTime"
import { numbreOfPeriodicInterventionAndPercentTime, tempoManutenzioneAsset } from "../../functions/metriche/tempiMan-Number"
import { getDerivatesInterForAPeriod, getFirstLevelActuative, getPeriodicInterventions } from "../../functions/metriche/filterList"
import { RTSTEAttivitaIntervento } from "../../functions/metriche/RTSTE"
import { OTDForPeriodicIntervention } from "../../functions/metriche/OTD"
import { pointToeconomicalValue } from "../../functions/generiche"
import { AssetMetriche, AttivitaMetriche, InterventionMetriche } from "./subElements/elementMetriche"
import { addMoreMetrics, selectAllMetriche } from "../root/slices/metricValueSlice"


const Metriche = () => {
    const metriche = useSelector(selectAllMetriche)

    const listIntervention = useSelector(selectAllIntervention)
    const listDepartment = useSelector(selectAllDepartment)
    const listLine = useSelector(selectAllLine)
    const listAsset = useSelector(selectAllMachinery)
    const listPlant = useSelector(selectPlant)

    const [listPeriodicIntervention, setPeriodicInter] = useState([])




    useEffect(() => {
        setPeriodicInter(getPeriodicInterventions(listIntervention))
    }, [listIntervention])




    return (
        <div className="bodyMetriche">

            <div style={{ display: "flex", marginBottom: "2vw" }}>
                <h3>Legenda :</h3>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "green"
                    }}
                >Stabilimento</span>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "blue"
                    }}
                >Reparto</span>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "violet"
                    }}
                >Linea</span>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "grey"
                    }}
                >Macchinario</span>
                <span
                    style={{
                        margin: "1vw 2vw",
                        color: "purple"
                    }}
                >intervento Periodico</span>
            </div>
            <ul>
                {
                    listPlant.map(plt => {
                        return <li
                            key={plt.id}
                            style={{ color: "green", margin: " 0vw 0vw 3vw 0vw" }}
                        >
                            <div style={{ display: "flex" }}>
                                <span >{plt.nome}</span>
                                <div >
                                    <span
                                        style={{
                                            margin: "0vw 1vw",
                                            color: "black"
                                        }}>
                                        costo-budget:


                                        {pointToeconomicalValue(metriche.find(el => el.id === plt.id)?.costiInterventi)}
                                        /
                                        {pointToeconomicalValue(metriche
                                            .find(e => e.id === plt.id)?.value)} €
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>

                                        tempo totale manutenzione: {metriche.find(el => el.id === plt.id)?.tempi} minuti
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        PMP: {
                                            isNaN(metriche.find(el => el.id === plt.id)?.tempEff
                                                /
                                                metriche.find(el => el.id === plt.id)?.tempi)
                                                ?
                                                0
                                                :
                                                Math.round((metriche.find(el => el.id === plt.id)?.tempEff
                                                    /
                                                    metriche.find(el => el.id === plt.id)?.tempi) * 100)

                                        } %
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        PMC: {metriche.find(el => el.id === plt.id)?.completeIntervention} / {metriche.find(el => el.id === plt.id)?.allIntervention}
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        On Time Delivery: {isNaN(metriche.find(el => el.id === plt.id)?.rapportoODT)
                                            ?
                                            "no data"
                                            :
                                            metriche.find(el => el.id === plt.id)?.rapportoODT + " %"
                                        }
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        RTSTE: {isNaN(metriche
                                            .find(tseL => plt.id === tseL?.id)?.sR
                                            /
                                            metriche
                                                .find(tseL => plt.id === tseL?.id)?.nR)
                                            ?
                                            " no data"
                                            :
                                            Math.round((metriche
                                                .find(e => plt.id === e?.id)?.sR
                                                /
                                                metriche
                                                    .find(e => plt.id === e?.id)?.nR) * 100) + " %"
                                        }
                                    </span>
                                    <span style={{ margin: "0vw 1vw", color: "black" }}>
                                        RTSTE-a: {isNaN(metriche.find(e => plt.id === e?.id)?.sRa / metriche.find(e => plt.id === e?.id)?.nRa) ?
                                            " no data"
                                            :
                                            Math.round((metriche.find(e => plt.id === e?.id)?.sRa / metriche.find(e => plt.id === e?.id)?.nRa) * 100) + " %"}
                                    </span>




                                </div>

                            </div>


                            <ul>
                                {listDepartment.filter(depF => plt.id === depF.idPlant).map(dep => {
                                    return <li key={dep.id} style={{ color: "blue", margin: "1vw 1vw" }}>
                                        <div style={{ display: "flex" }}>
                                            <span >{dep.name}</span>
                                            <div >
                                                <span style={{
                                                    margin: "0vw 1vw",
                                                    color: "black"
                                                }}>
                                                    costo-budget:{pointToeconomicalValue(metriche.find(el => el.id === dep.id)?.costiInterventi)}
                                                    /
                                                    {pointToeconomicalValue(metriche
                                                        .find(e => e.id === dep.id)?.value)} €
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    tempo totale manutenzione: {metriche.find(el => el.id === dep.id)?.tempi} minuti
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    PMP: {
                                                        isNaN(metriche.find(el => el.id === dep.id)?.tempEff
                                                            /
                                                            metriche.find(el => el.id === dep.id)?.tempi)
                                                            ?
                                                            0
                                                            :
                                                            Math.round((metriche.find(el => el.id === dep.id)?.tempEff
                                                                /
                                                                metriche.find(el => el.id === dep.id)?.tempi) * 100)

                                                    } %
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    PMC: {metriche.find(el => el.id === dep.id)?.completeIntervention} / {metriche.find(el => el.id === dep.id)?.allIntervention}
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    On Time Delivery: {isNaN(metriche.find(el => el.id === dep.id)?.rapportoODT)
                                                        ?
                                                        "no data"
                                                        :
                                                        `${metriche.find(el => el.id === dep.id)?.rapportoODT} %`
                                                    }
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    RTSTE: {isNaN(metriche
                                                        .find(tseL => dep.id === tseL?.id)?.sR
                                                        /
                                                        metriche
                                                            .find(tseL => dep.id === tseL?.id)?.nR)
                                                        ?
                                                        " no data"
                                                        :
                                                        Math.round((
                                                            metriche
                                                                .find(e => dep.id === e?.id)?.sR
                                                            /
                                                            metriche
                                                                .find(e => dep.id === e?.id)?.nR) * 100) + " %"
                                                    }
                                                </span>
                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                    RTSTE-a: {isNaN(metriche.find(e => dep.id === e?.id)?.sRa / metriche.find(e => dep.id === e?.id)?.nRa) ?
                                                        " no data"
                                                        :
                                                        Math.round((metriche.find(e => dep.id === e?.id)?.sRa / metriche.find(e => dep.id === e?.id)?.nRa) * 100) + " %"}
                                                </span>


                                            </div>

                                        </div>


                                        <ul>
                                            {listLine.filter(linF => dep.id === linF.idDepartment)
                                                .map(lin => {
                                                    return <li
                                                        key={lin.id}
                                                        style={{ margin: "1vw 1vw", color: "violet" }}
                                                    >
                                                        <div style={{ display: "flex" }}>
                                                            <span >{lin.name}</span>
                                                            <div
                                                            >
                                                                <span style={{
                                                                    margin: "0vw 1vw",
                                                                    color: "black"
                                                                }}>

                                                                    costo-budget:{pointToeconomicalValue(metriche.find(el => el.id === lin.id)?.costiInterventi) === 0 ? "0" : pointToeconomicalValue(metriche.find(el => el.id === lin.id)?.costiInterventi)}
                                                                    /
                                                                    {pointToeconomicalValue(metriche
                                                                        .find(e => e.id === lin.id)?.value)} €
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    tempo totale manutenzione: {metriche.find(el => el.id === lin.id)?.tempi} minuti
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    PMP: {
                                                                        isNaN(metriche.find(el => el.id === lin.id)?.tempEff
                                                                            /
                                                                            metriche.find(el => el.id === lin.id)?.tempi)
                                                                            ?
                                                                            0
                                                                            :
                                                                            Math.round((metriche.find(el => el.id === lin.id)?.tempEff
                                                                                /
                                                                                metriche.find(el => el.id === lin.id)?.tempi) * 100)

                                                                    } %
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    PMC: {metriche.find(el => el.id === lin.id)?.completeIntervention} / {metriche.find(el => el.id === lin.id)?.allIntervention}
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    On Time Delivery: {isNaN(metriche.find(el => el.id === lin.id)?.rapportoODT)
                                                                        ?
                                                                        "no data"
                                                                        :
                                                                        metriche.find(el => el.id === lin.id)?.rapportoODT + " %"
                                                                    }
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    RTSTE: {isNaN(metriche
                                                                        .find(tseL => lin.id === tseL?.id)?.sR
                                                                        /
                                                                        metriche
                                                                            .find(tseL => lin.id === tseL?.id)?.nR)
                                                                        ?
                                                                        " no data"
                                                                        :
                                                                        Math.round((metriche
                                                                            .find(e => lin.id === e?.id)?.sR
                                                                            /
                                                                            metriche
                                                                                .find(e => lin.id === e?.id)?.nR) * 100) + " %"
                                                                    }
                                                                </span>
                                                                <span style={{ margin: "0vw 1vw", color: "black" }}>
                                                                    RTSTE-a: {isNaN(metriche.find(e => lin.id === e?.id)?.sRa / metriche.find(e => lin.id === e?.id)?.nRa) ?
                                                                        " no data"
                                                                        :
                                                                        Math.round((metriche.find(e => lin.id === e?.id)?.sRa / metriche.find(e => lin.id === e?.id)?.nRa) * 100) + " %"}
                                                                </span>

                                                            </div>
                                                        </div>

                                                        <ul style={{ flex: "10" }}>
                                                            {listAsset
                                                                .filter(assF => lin.id === assF.idLinea)
                                                                .map(ass => {
                                                                    return <AssetMetriche
                                                                        key={ass.id}
                                                                        ass={ass}
                                                                        costiPerAsset={metriche}
                                                                        tManAss={metriche}
                                                                        tpManAss={metriche}
                                                                        ieManAss={metriche}
                                                                        otdManAss={metriche}
                                                                        tseManAss={metriche}
                                                                        listPeriodicIntervention={listPeriodicIntervention}
                                                                        otdManIp={metriche}
                                                                        tseManIp={metriche}

                                                                    />
                                                                })}
                                                        </ul>
                                                    </li>
                                                })}
                                        </ul>
                                    </li>
                                })}
                            </ul>
                        </li>
                    })
                }
            </ul >
        </div >
    )

}


export default Metriche