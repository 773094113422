import React, { useEffect, useState } from "react";
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import Icon from '../../../icon/localizzatore.svg';
import { selectStructure } from "../../../structures/root/slices/selectionForMetricheSlice";
import { useSelector } from "react-redux";
import { getSelectPlant } from "../../../structures/root/slices/plantSlice";

//funzione di supporto per il cambio centro
const RecenterAutomatically = ({ center, zoom }) => {
    const [cen, setCen] = useState(center)
    const [zoo, setZoo] = useState(zoom)
    const mapEv = useMapEvent({

        zoom: () => {
            setZoo(mapEv.getZoom())

        },
        dblclick: (e) => {
            
            setZoo(mapEv.getZoom())
            
        }

    })
    const map = useMap();

    useEffect(() => {
        if (cen[0] === 0) {
            setCen(center)
            setZoo(zoom)
        }


    }, [center, zoom])

    useEffect(() => {
        map.setView(cen, zoo);
    }, [cen]);

    useEffect(() => {
        map.setZoom(zoo)
    }, [zoo])

    return null;
}






const LeafletMap = ({ plants, selezioneImpianto, selezioneImpiantoMetriche }) => {

    const plantSelected = useSelector(getSelectPlant)

    useEffect(() => {

        selezioneImpiantoMetriche({
            idStructure: plantSelected?.id
        })
    }, [plantSelected])


    const [myIcon, setMyIcon] = useState(L.icon({
        iconUrl: Icon,
        iconSize: [30, 30],
        iconAnchor: [15, 30],
        popupAnchor: [0, -30],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    }))

    const ricercaCentro = (vettPlants) => {
        let sommLat = 0
        let sommLon = 0
        if (vettPlants.length !== 0) {
            const lungh = vettPlants.length
            vettPlants.forEach(element => {

                sommLat = sommLat + element[0]
                sommLon = sommLon + element[1]
            })
            const lat = Math.round(sommLat / lungh)
            const lon = Math.round(sommLon / lungh)

            return [lat, lon]
        }
        else {
            return [0, 0]
        }

    }

    const [center, setCenter] = useState([0, 0])
    const [zoom, setZoom] = useState(5)


    const ricercaZoom = (vettPlants) => {
        let distMax = 0
        const angoloZoom = 40
        for (let i = 0; i < vettPlants.length; i++) {
            for (let j = 0; j < vettPlants.length; j++) {
                const ipote = Math.sqrt(
                    Math.pow(vettPlants[i][0] - vettPlants[j][0], 2)
                    +
                    Math.pow(vettPlants[i][1] - vettPlants[j][1], 2)
                )

                if (distMax < ipote) {
                    distMax = ipote
                }
            }
        }


        if (Math.floor((1 / (distMax / angoloZoom))) > 6) {
            return 6
        } else {
            if (Math.floor((1 / (distMax / angoloZoom)) < 1)) {
                return 1
            } else {
                return Math.floor((1 / (distMax / angoloZoom)))
            }

        }

    }

    useEffect(() => {
        //ho impostato un ritardo poiché questo calcolo è più veloce della richiesta della mappa e di conseguenza anche se gli passo il centro e lo zoom la mappa lo ignora
        setTimeout(() => {
            const center = ricercaCentro(plants.map(el => el.posGeo))
            const zoom = ricercaZoom(plants.map(el => el.posGeo))
            setCenter(center)
            setZoom(zoom)
        }, 2000)


    }, [plants])




    return (

        <MapContainer
            maxZoom={15}
            scrollWheelZoom={false} >
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {
                plants.map((el) => {
                    return <Marker
                        key={el.id}
                        position={el.posGeo}
                        //position={[0,0]}
                        icon={myIcon}
                        eventHandlers={{
                            click: () => {


                                selezioneImpianto({ plantId: el.id })
                            }
                        }}
                    >
                        <Popup>
                            {`Stabilimento : ${el.nome} `}
                        </Popup>
                    </Marker>
                }
                )
            }
            <RecenterAutomatically
                center={center}
                zoom={zoom}
            />
        </MapContainer>

    )

}

export default LeafletMap