/**
 * Impianto singolo o distribuito
 * variazioni:
 * - no mappa ma sinottico
 * -inserimento evento solo reparto e id macchinario
 */

export const isASinglePlant = false

export const pathOverview = '/overview'
export const pathAlertView = '/alertview'
export const pathExplorPlant = '/explorePlant'
export const pathPlanninng = '/planning'
export const pathNotification = '/notifiche'
export const pathImpostazioni = '/impostazioni'
export const pathMetriche ="/metriche"

export const pathMachineryFaultList = 'machineryfaultlist'
export const pathMachineryDocumentation = 'machinerydocumentation'
export const pathMachineryIntervention = 'machineryintervention'
export const pathMachineryMetriche ='machinerymetriche'

export const statiElaborazioneIntervento = ["inattivo", "Da programmare", "Programmato"]

export const GIORNIMILLISECONDI = 86400000

export const periodiSelezionabiliInterventoPeriodico = [
    {
        label: "none",
        value: ""
    },
    {
        label: "day",
        value: 1
    },
    {
        label: "1 Week",
        value: 7
    },
    {
        label: "2 Week",
        value: 14
    },
    {
        label: "1 Month",
        value: 30
    },
    {
        label: "3 Months",
        value: 90
    },
    {
        label: "6 Months",
        value: 180
    },
    {
        label: "1 Year",
        value: 365
    }
]

export const preloadStateDefault = {
    listLine: { ids: [], entities: {} },
    listDepartment: { ids: [], entities: {} },
    listBudgets: { ids: [], entities: {} },
    alertAlgoritm: { ids: [], entities: {} },
    dataFaultMachinery: { ids: [], entities: {} },
    dataPlant: { ids: [], entities: {} },
    datiTargaMachinery: { ids: [], entities: {} },
    datiUtente: { ids: [], entities: {} },
    dayCalendar: { date: '2023-11-08' },
    docDatiTarga: { ids: [], entities: {} },
    family: { ids: [], entities: {} },
    fault: { ids: [], entities: {} },
    intervention: { ids: [], entities: {} },
    listMachinery: { ids: [], entities: {} },
    notificationFilter: { status: 'all' },
    plants: { ids: [], entities: {} },
    report: { ids: [], entities: {} },
    schedaMachineDetail: { ids: [], entities: {} },
    ticket: { ids: [], entities: {} }
}