import { useDispatch, useSelector } from "react-redux"
import { selectAllIntervention } from "../../root/slices/interventionSlice"
import { selectAllBudget } from "../../root/slices/budgetSlice"
import { selectAllDepartment } from "../../root/slices/listDepartmentSlice"
import { selectAllLine } from "../../root/slices/listLineSlice"
import { selectAllMachinery } from "../../root/slices/listMachinerySlice"
import { selectPlant } from "../../root/slices/plantSlice"
import { selectAllReport } from "../../root/slices/reportSlice"
import { useEffect, useState } from "react"
import { addMoreMetrics } from "../../root/slices/metricValueSlice"
import { getDerivatesInterForAPeriod, getFirstLevelActuative, getPeriodicInterventions } from "../../../functions/metriche/filterList"
import { RTSTEAttivitaIntervento } from "../../../functions/metriche/RTSTE"
import { OTDForPeriodicIntervention } from "../../../functions/metriche/OTD"
import { numbreOfPeriodicInterventionAndPercentTime, tempoManutenzioneAsset } from "../../../functions/metriche/tempiMan-Number"
import { costoPerAsset } from "../../../functions/metriche/budget"
import selectionForMetricheSlice, { getSelectionMetrics, selectPeriod } from "../../root/slices/selectionForMetricheSlice"
import { GIORNIMILLISECONDI } from "../../../configurations/setting"




const MetricCalculation = () => {
    //lista interventi per tutte le macchine esistenti



    const dispatch = useDispatch()
    const addMetriche = (metriche) => dispatch(addMoreMetrics(metriche))

    const periodOfCalculation = useSelector(getSelectionMetrics)

    const listIntervention = useSelector(selectAllIntervention)
    const listBudget = useSelector(selectAllBudget)
    const listDepartment = useSelector(selectAllDepartment)
    const listLine = useSelector(selectAllLine)
    const listAsset = useSelector(selectAllMachinery)
    const listPlant = useSelector(selectPlant)
    const listReport = useSelector(selectAllReport)

    const [listPeriodicIntervention, setPeriodicInter] = useState([])
    const [listinterventionPeriodicANDderivates, setListInterPandDeriv] = useState([])
    //raccolgo gli interventi che sono collegati ad un intervento periodico non eliminato
    const [listInterventioAttuative, setListAttuative] = useState([])

    //VARIABILI NUMERO DI INTERVENTI RISPETTO AL TOTALE PERIODICI

    const dataOdierna = new Date()
    const [dateStart, setDateStart] = useState(new Date(dataOdierna.getTime() - (GIORNIMILLISECONDI * 365)).toISOString().slice(0, 10))
    //VARIABILI ONE TIME DELIVERY ok
    const [otdManIp, setotdManIp] = useState([])
    const [otdManAss, setotdManAss] = useState([])
    const [otdManLin, setotdManLin] = useState([])
    const [otdManDep, setotdManDep] = useState([])
    const [otdManPlt, setotdManPlt] = useState([])


    //VARIABILI PER RAPPORTO TEMPO STIMATO TEMPO EFFETTIVO ok
    const [tseManIp, settseManIp] = useState([])
    const [tseManAss, settseManAss] = useState([])
    const [tseManLin, settseManLin] = useState([])
    const [tseManDep, settseManDep] = useState([])
    const [tseManPlt, settseManPlt] = useState([])

    //VARIABILI PER NUEMRO DI INTERVENTI PERIODICI ESEGUITI RISPETTO AL TOTALE ok
    const [ieManAss, setIeManAss] = useState([])
    const [ieManLin, setIeManLin] = useState([])
    const [ieManDep, setIeManDep] = useState([])
    const [ieManPlt, setIeManPlt] = useState([])
    //VARIABILI TEMPI TOT MANUTENZIONE OK
    const [tManAss, setTManAss] = useState([])
    const [tManLin, setTManLin] = useState([])
    const [tManDep, setTManDep] = useState([])
    const [tManPlt, setTManPlt] = useState([])


    // TEMPO DEI SOLI INTERVENTI PERIODICI EFFETTIVO (PRESO DAI REPORT) ok
    const [tpManAss, setTpManAss] = useState([])
    const [tpManLin, setTpManLin] = useState([])
    const [tpManDep, setTpManDep] = useState([])
    const [tpManPlt, setTpManPlt] = useState([])
    // VARIABILI PER IL BUDGET    OK
    const [costPlant, setCostPlant] = useState([])
    const [costoDipart, setCostDip] = useState([])
    const [costiLinea, setCostiLinea] = useState([])
    const [costiPerAsset, setCostiAsset] = useState([])
    /**
     * [
     * {
     *  idAsset:"",
     *  costiInterventi:""
     * }
     * ]
     */


    //PREPARAZIONE INIZIALE

    //imposto data di inizio e fine nel caso del cambio 
    useEffect(() => {
        switch (periodOfCalculation.period) {
            //settimana prima
            case 0:
                setDateStart(new Date(dataOdierna.getTime() - (GIORNIMILLISECONDI * 7)).toISOString().slice(0, 10))
                break
            //mese prima
            case 1:
                setDateStart(new Date(dataOdierna.getTime() - (GIORNIMILLISECONDI * 30)).toISOString().slice(0, 10))
                break
            //anno prima
            case 2:
                setDateStart(new Date(dataOdierna.getTime() - (GIORNIMILLISECONDI * 365)).toISOString().slice(0, 10))
                break
            default:
                setDateStart(new Date(dataOdierna.getTime() - (GIORNIMILLISECONDI * 365)).toISOString().slice(0, 10))


        }
    }, [periodOfCalculation, dataOdierna])


    useEffect(() => {
        setPeriodicInter(getPeriodicInterventions(listIntervention))
    }, [listIntervention, listReport, dateStart])

    useEffect(() => {
        setListAttuative(getFirstLevelActuative(
            listIntervention,
            listPeriodicIntervention,
            dateStart,
            dataOdierna.toISOString().slice(0, 10)))
    }, [listPeriodicIntervention])

    useEffect(() => {
        setListInterPandDeriv(getDerivatesInterForAPeriod(listPeriodicIntervention, listInterventioAttuative, listIntervention))
    }, [listInterventioAttuative])

    useEffect(() => {
        addMetriche(listBudget.filter(element => Number(element.year) === dataOdierna.getFullYear())
            .map(e => { return { id: e.idStructure, value: e.value } }))
    }, [listBudget, dateStart])
    //RAPPORTO TEMPO STIMATO INTERVENTO ED EFFETTIVO
    useEffect(() => {
        if (listInterventioAttuative.length !== 0) {
            settseManIp(RTSTEAttivitaIntervento(listIntervention, listIntervention, listInterventioAttuative, listReport))
        }
    }, [listInterventioAttuative, dateStart])

    useEffect(() => {
        settseManAss(listAsset.map(ass => {
            const listInterventionForMachine = listPeriodicIntervention.filter(i => i.idMachinery === ass.id)
            const DataForInterventionMachine = listInterventionForMachine.map(i => tseManIp.find(e => e?.id === i.id))

            const value = DataForInterventionMachine.reduce((a, i) => {

                if (a === undefined) {
                    return {
                        id: ass.id,
                        name: ass.nome,
                        sRa: i?.RTSTEa
                            ?
                            i.RTSTEa >= 1
                                ?
                                1
                                :
                                i.RTSTEa
                            :
                            0,

                        nRa: i?.RTSTEa ? 1 : 0,
                        sR: i?.RTSTE
                            ?
                            i.RTSTE >= 1
                                ?
                                1
                                :
                                i.RTSTE
                            :
                            0,
                        nR: i?.RTSTE ? 1 : 0
                    }
                } else {
                    return {
                        id: ass.id,
                        name: ass.nome,
                        sRa: i?.RTSTEa
                            ?
                            i.RTSTEa >= 1
                                ?
                                a.sRa + 1
                                :
                                a.sRa + i.RTSTEa
                            :
                            a.sRa,

                        nRa: i?.RTSTEa ? a.nRa + 1 : a.nRa,
                        sR: i?.RTSTE
                            ?
                            i.RTSTE >= 1
                                ?
                                a.sR + 1
                                :
                                a.sR + i.RTSTE
                            :
                            a.sRa,
                        nR: i?.RTSTE ? a.nR + 1 : a.nR
                    }
                }
            }, undefined)



            return value
        }))

    }, [tseManIp, listPeriodicIntervention, listAsset])


    useEffect(() => {
        settseManLin(listLine.map(lin => {
            const listassLinea = listAsset.filter(a => a.idLinea === lin.id)
            const data = listassLinea.map(i => tseManAss.find(e => e?.id === i.id))

            const value = data.reduce((a, i) => {
                if (a === undefined) {
                    return {
                        id: lin.id,
                        sRa: i?.sRa
                            ?
                            i.sRa / i.nRa
                            :
                            0,

                        nRa: i?.sRa ? 1 : 0,
                        sR: i?.sR
                            ?
                            i.sR / i.nR
                            :
                            0,
                        nR: i?.sR ? 1 : 0
                    }
                } else {

                    if (i === undefined) {
                        return a
                    } else {
                        return {
                            name: lin.name,
                            id: lin.id,
                            sRa: i?.sRa
                                ?

                                a.sRa + (i.sRa / i.nRa)
                                :
                                a.sRa,

                            nRa: i?.sRa ? a.nRa + 1 : a.nRa,
                            sR: i?.sR
                                ?
                                a.sR + (i.sR / i.nR)
                                :
                                a.sR,
                            nR: i?.sR ? a.nR + 1 : a.nR
                        }
                    }

                }
            }, undefined)



            return value
        }))
    }, [tseManAss, listLine, listAsset])




    useEffect(() => {
        settseManDep(listDepartment.map(dep => {
            const listaLin = listLine.filter(a => a.idDepartment === dep.id)
            const data = listaLin.map(i => tseManLin.find(e => e?.id === i.id))

            const value = data.reduce((a, i) => {

                if (a === undefined) {
                    return {
                        id: dep.id,
                        sRa: i?.sRa
                            ?
                            i.sRa / i.nRa
                            :
                            0,

                        nRa: i?.sRa ? 1 : 0,
                        sR: i?.sR
                            ?
                            i.sR / i.nR
                            :
                            0,
                        nR: i?.sR ? 1 : 0
                    }
                } else {
                    return {

                        id: dep.id,
                        sRa: i?.sRa
                            ?

                            a.sRa + (i.sRa / i.nRa)
                            :
                            a.sRa,

                        nRa: i?.sRa ? a.nRa + 1 : a.nRa,
                        sR: i?.sR
                            ?
                            a.sR + (i.sR / i.nR)
                            :
                            a.sR,
                        nR: i?.sR ? a.nR + 1 : a.nR
                    }
                }
            }, undefined)



            return value
        }))
    }, [tseManLin, listDepartment, listLine])



    useEffect(() => {
        settseManPlt(listPlant.map(plt => {
            const listaDep = listDepartment.filter(a => a.idPlant === plt.id)
            const data = listaDep.map(i => tseManDep.find(e => e?.id === i.id))
            const value = data.reduce((a, i) => {

                if (a === undefined) {

                    return {
                        id: plt.id,
                        sRa: i?.sRa
                            ?
                            i.sRa / i.nRa
                            :
                            0,

                        nRa: i?.sRa ? i.sRa !== 0 ? 1 : 0 : 0,
                        sR: i?.sR
                            ?
                            i.sR / i.nR
                            :
                            0,
                        nR: i?.sR ? i.sR !== 0 ? 1 : 0 : 0
                    }
                } else {
                    if (i === undefined) {
                        return a
                    } else {

                        return {

                            id: plt.id,
                            sRa: i?.sRa
                                ?
                                a.sRa + (i.sRa / i.nRa)
                                :
                                a.sRa,

                            nRa: i?.sRa ? i.sRa !== 0 ? a.nRa + 1 : a.nRa : a.nRa,
                            sR: i?.sR
                                ?
                                a.sR + (i.sR / i.nR)
                                :
                                a.sR,
                            nR: i?.sR ? i.sR !== 0 ? a.nR + 1 : a.nR : a.nR
                        }
                    }

                }
            }, undefined)



            return value
        }))
        //aggiungo metriche per intervento, linea, reparto, stabilimento

    }, [tseManDep, listPlant, listDepartment])

    useEffect(() => {
        addMetriche(
            [...tseManIp.filter(element => element !== undefined),
            ...tseManAss.filter(element => element !== undefined),
            ...tseManLin.filter(element => element !== undefined),
            ...tseManDep.filter(element => element !== undefined),
            ...tseManPlt.filter(element => element !== undefined)]

        )
    }, [tseManPlt])
    //ON TIME DELIVERY
    useEffect(() => {
        setotdManIp(OTDForPeriodicIntervention(listPeriodicIntervention, listIntervention, listInterventioAttuative, listReport))
    }, [listInterventioAttuative, dateStart, listReport, listIntervention])

    useEffect(() => {
        setotdManAss(

            listAsset.map(ass => {
                let odtAssetTemp = 0
                let count = 0
                listPeriodicIntervention
                    .filter(ip => ip.idMachinery === ass.id)
                    .forEach(el => {
                        if (!isNaN(otdManIp.find(e => e.id === el.id)?.rapportoODT)) {
                            odtAssetTemp = odtAssetTemp + otdManIp.find(e => e.id === el.id).rapportoODT
                            count = count + 1
                        }

                    })
                return {
                    id: ass.id,
                    rapportoODT: Math.round(odtAssetTemp / count)
                }
            })
        )
    }, [otdManIp, listAsset, listPeriodicIntervention])

    useEffect(() => {
        setotdManLin(

            listLine.map(lin => {
                let odtAssetTemp = 0
                let count = 0
                listAsset
                    .filter(ass => ass.idLinea === lin.id)
                    .forEach(el => {
                        if (!isNaN(otdManAss.find(e => e.id === el.id)?.rapportoODT)) {
                            odtAssetTemp = odtAssetTemp + otdManAss.find(e => e.id === el.id).rapportoODT
                            count = count + 1
                        }

                    })
                return {
                    id: lin.id,
                    rapportoODT: Math.round(odtAssetTemp / count)
                }
            })
        )
    }, [otdManAss, listLine, listAsset])

    useEffect(() => {
        setotdManDep(

            listDepartment.map(dep => {
                let odtAssetTemp = 0
                let count = 0
                listLine
                    .filter(lin => lin.idDepartment === dep.id)
                    .forEach(el => {
                        if (!isNaN(otdManLin.find(e => e.id === el.id)?.rapportoODT)) {
                            odtAssetTemp = odtAssetTemp + otdManLin.find(e => e.id === el.id).rapportoODT
                            count = count + 1
                        }

                    })
                return {
                    id: dep.id,
                    rapportoODT: Math.round(odtAssetTemp / count)
                }
            })
        )
    }, [otdManLin, listDepartment, listLine])

    useEffect(() => {
        setotdManPlt(

            listPlant.map(plt => {
                let odtAssetTemp = 0
                let count = 0
                listDepartment
                    .filter(dep => dep.idPlant === plt.id)
                    .forEach(el => {
                        if (!isNaN(otdManDep.find(e => e.id === el.id)?.rapportoODT)) {
                            odtAssetTemp = odtAssetTemp + otdManDep.find(e => e.id === el.id).rapportoODT
                            count = count + 1
                        }

                    })
                return {
                    id: plt.id,
                    rapportoODT: Math.round(odtAssetTemp / count)
                }
            })
        )

    }, [otdManDep, listPlant, listDepartment])

    useEffect(() => {
        addMetriche(
            [...otdManIp.filter(element => element !== undefined),
            ...otdManAss.filter(element => element !== undefined),
            ...otdManLin.filter(element => element !== undefined),
            ...otdManDep.filter(element => element !== undefined),
            ...otdManPlt.filter(element => element !== undefined)]

        )
    }, [otdManPlt])

    // TEMPI EFFETTIVI DELLA MANUTENZIONE PROGRAMMATA RISPETTO ALLA TOTALE EL IL LORO NUMERO

    useEffect(() => {

        const [listEventPeridoComplete, listAssTempP] = numbreOfPeriodicInterventionAndPercentTime(
            listAsset,
            listReport,
            listIntervention,
            listPeriodicIntervention,
            listInterventioAttuative,
            listinterventionPeriodicANDderivates, dateStart, dataOdierna.toISOString().slice(0, 10))

        setIeManAss(listEventPeridoComplete)
        setTpManAss(listAssTempP)


    }, [listinterventionPeriodicANDderivates, dateStart, listAsset, listReport])

    useEffect(() => {
        setIeManLin(
            listLine
                .map(lin => {
                    //lista asset per linea
                    const vettoreAssetLinee = listAsset.filter(ass => ass.idLinea === lin.id)

                    let sommaItot = 0
                    let sommaIcom = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => {
                        sommaItot = ieManAss.find(cass => cass.id === vass.id)?.allIntervention + sommaItot
                        sommaIcom = ieManAss.find(cass => cass.id === vass.id)?.completeIntervention + sommaIcom
                    })
                    //ritorna la somma
                    return {
                        id: lin.id,
                        allIntervention: sommaItot,
                        completeIntervention: sommaIcom
                    }
                }))
    }, [ieManAss, listLine, listAsset])



    useEffect(() => {
        setIeManDep(
            listDepartment
                .map(dep => {
                    //lista asset per linea
                    const vettoreAssetLinee = listLine.filter(lin => lin.idDepartment === dep.id)

                    let sommaItot = 0
                    let sommaIcom = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => {
                        sommaItot = ieManLin.find(cass => cass.id === vass.id)?.allIntervention + sommaItot
                        sommaIcom = ieManLin.find(cass => cass.id === vass.id)?.completeIntervention + sommaIcom
                    })
                    //ritorna la somma
                    return {
                        id: dep.id,
                        allIntervention: sommaItot,
                        completeIntervention: sommaIcom
                    }
                }))
    }, [ieManLin, listDepartment, listLine])


    useEffect(() => {
        setIeManPlt(
            listPlant
                .map(plt => {

                    const vettoreAssetLinee = listDepartment.filter(dep => dep.idPlant === plt.id)

                    let sommaItot = 0
                    let sommaIcom = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => {
                        sommaItot = ieManDep.find(cass => cass.id === vass.id)?.allIntervention + sommaItot
                        sommaIcom = ieManDep.find(cass => cass.id === vass.id)?.completeIntervention + sommaIcom
                    })
                    //ritorna la somma
                    return {
                        id: plt.id,
                        allIntervention: sommaItot,
                        completeIntervention: sommaIcom
                    }
                }))


    }, [ieManDep, listPlant, listDepartment])

    useEffect(() => {
        addMetriche(
            [...ieManAss.filter(element => element !== undefined),
            ...ieManLin.filter(element => element !== undefined),
            ...ieManDep.filter(element => element !== undefined),
            ...ieManPlt.filter(element => element !== undefined)]
        )
    }, [ieManPlt])



    useEffect(() => {
        setTpManLin(
            listLine
                .map(lin => {
                    //lista asset per linea
                    const vettoreAssetLinee = listAsset.filter(ass => ass.idLinea === lin.id)

                    let sommaLinea = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => sommaLinea = tpManAss.find(cass => cass.id === vass.id)?.tempEff + sommaLinea)
                    //ritorna la somma
                    return {
                        id: lin.id,
                        tempEff: sommaLinea
                    }
                }))
    }, [tpManAss, listLine, listAsset])

    useEffect(() => {
        setTpManDep(
            listDepartment
                .map(dep => {
                    //lista asset per linea
                    const vettorelineeDep = listLine.filter(lin => lin.idDepartment === dep.id)

                    let sommadep = 0
                    //calcolo della spesa per linea
                    vettorelineeDep.forEach(vlin => sommadep = tpManLin.find(clin => clin.id === vlin.id)?.tempEff + sommadep)
                    //ritorna la somma
                    return {
                        id: dep.id,
                        tempEff: sommadep
                    }
                }))
    }, [tpManLin, listLine, listDepartment])

    useEffect(() => {
        setTpManPlt(
            listPlant.map(plt => {
                const vettorerepplant = listDepartment.filter(dep => dep.idPlant === plt.id)
                let sommaPlt = 0
                vettorerepplant.forEach(vdep => sommaPlt = tpManDep.find(cdep => cdep.id === vdep.id)?.tempEff + sommaPlt)
                return {
                    id: plt.id,
                    tempEff: sommaPlt
                }
            })
        )

    }, [tpManDep, listPlant, listDepartment])

    useEffect(() => {
        addMetriche(
            [...tpManAss.filter(element => element !== undefined),
            ...tpManLin.filter(element => element !== undefined),
            ...tpManDep.filter(element => element !== undefined),
            ...tpManPlt.filter(element => element !== undefined)]
        )
    }, [tpManPlt])

    //CALCOLO DELLE METRICHE PER IL BUDGET
    //budget per macchina
    useEffect(() => {
        //per ogni asset
        setCostiAsset(
            costoPerAsset({ listAsset, listReport, dateStart })
        )
    }, [listReport, dateStart, listAsset])

    //valore interventi per layer
    useEffect(() => {
        //calcoliamo i costi delle linee
        setCostiLinea(
            listLine
                .map(lin => {
                    //lista asset per linea
                    const vettoreAssetLinee = listAsset.filter(ass => ass.idLinea === lin.id)

                    let sommaLinea = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => sommaLinea = costiPerAsset.find(cass => cass.id === vass.id)?.costiInterventi + sommaLinea)
                    //ritorna la somma
                    return {
                        id: lin.id,
                        costiInterventi: sommaLinea
                    }
                }))

    }, [costiPerAsset, listLine, listAsset])

    useEffect(() => {
        setCostDip(
            listDepartment
                .map(dep => {
                    //lista asset per linea
                    const vettorelineeDep = listLine.filter(lin => lin.idDepartment === dep.id)

                    let sommadep = 0
                    //calcolo della spesa per linea
                    vettorelineeDep.forEach(vlin => sommadep = costiLinea.find(clin => clin.id === vlin.id)?.costiInterventi + sommadep)
                    //ritorna la somma
                    return {
                        id: dep.id,
                        costiInterventi: sommadep
                    }
                }))
    }, [costiLinea, listLine, listDepartment])


    useEffect(() => {
        setCostPlant(
            listPlant.map(plt => {
                const vettorerepplant = listDepartment.filter(dep => dep.idPlant === plt.id)
                let sommaPlt = 0
                vettorerepplant.forEach(vdep => sommaPlt = costoDipart.find(cdep => cdep.id === vdep.id)?.costiInterventi + sommaPlt)
                return {
                    id: plt.id,
                    costiInterventi: sommaPlt
                }
            })
        )

    }, [costoDipart, listDepartment, listPlant])


    useEffect(() => {
        addMetriche(
            [...costiPerAsset.filter(element => element !== undefined).map(e => { return { ...e, dateStart: dateStart } }),
            ...costiLinea.filter(element => element !== undefined).map(e => { return { ...e, dateStart: dateStart } }),
            ...costoDipart.filter(element => element !== undefined).map(e => { return { ...e, dateStart: dateStart } }),
            ...costPlant.filter(element => element !== undefined).map(e => { return { ...e, dateStart: dateStart } })])
    }, [costPlant])

    // CALCOLO DEI TEMPI TOTALI DI MANUTENZIONE

    useEffect(() => {
        //per ogni asset
        setTManAss(tempoManutenzioneAsset({ listAsset: listAsset, listReport: listReport, listIntervention: listIntervention, dateStart: dateStart }))
    }, [listReport, dateStart, listAsset, listIntervention])


    useEffect(() => {
        //calcoliamo i costi delle linee
        setTManLin(
            listLine
                .map(lin => {
                    //lista asset per linea
                    const vettoreAssetLinee = listAsset.filter(ass => ass.idLinea === lin.id)

                    let sommaLinea = 0
                    //calcolo della spesa per linea
                    vettoreAssetLinee.forEach(vass => sommaLinea = tManAss.find(cass => cass.id === vass.id)?.tempi + sommaLinea)
                    //ritorna la somma
                    return {
                        id: lin.id,
                        tempi: sommaLinea
                    }
                }))

    }, [tManAss, listAsset , listLine])

    useEffect(() => {
        setTManDep(
            listDepartment
                .map(dep => {
                    //lista asset per linea
                    const vettorelineeDep = listLine.filter(lin => lin.idDepartment === dep.id)

                    let sommadep = 0
                    //calcolo della spesa per linea
                    vettorelineeDep.forEach(vlin => sommadep = tManLin.find(clin => clin.id === vlin.id)?.tempi + sommadep)
                    //ritorna la somma
                    return {
                        id: dep.id,
                        tempi: sommadep
                    }
                }))
    }, [tManLin, listDepartment, listLine])

    useEffect(() => {
        setTManPlt(
            listPlant.map(plt => {
                const vettorerepplant = listDepartment.filter(dep => dep.idPlant === plt.id)
                let sommaPlt = 0
                vettorerepplant.forEach(vdep => sommaPlt = tManDep.find(cdep => cdep.id === vdep.id)?.tempi + sommaPlt)
                return {
                    id: plt.id,
                    tempi: sommaPlt
                }
            })
        )

    }, [tManDep, listPlant, listDepartment])

    useEffect(() => {
        addMetriche(
            [...tManAss.filter(element => element !== undefined),
            ...tManLin.filter(element => element !== undefined),
            ...tManDep.filter(element => element !== undefined),
            ...tManPlt.filter(element => element !== undefined)])
    }, [tManPlt])


    
    return <span></span>

}

export default MetricCalculation